import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
const GlobalStyles = createGlobalStyle`
    ${reset};
    *{
        box-sizing: border-box;
    }
    body{
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 13px;
        line-height: 1.42857;
        color: #333333;
    }
    header{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    button {
        cursor: pointer;
        padding: 0;
        font-size: inherit;
    }
     ul,li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    a {
        background-color: transparent;
    }
    p{
        margin: 0;
        outline: 0;
        border: 0;
        padding: 0;
        background: transparent;
    }
`;
export default GlobalStyles;
