const header = [
    {
        id: 1,
        title: '회사 소개',
        href: '/company/info'
    },
    {
        id: 2,
        title: '제품 소개',
        href: '/products/list'
    },
    {
        id: 3,
        title: '특허 · 인증서',
        href: '/board/certificate'
    },
    {
        id: 4,
        title: '오시는 길',
        href: '/direction'
    }
]
export default header