const theme = {
  mainColor: "#6ABD8C",
  mainColorLight: "#CCDFB0",
  backgroundColor: "WHITE",
  darkGray: "#595959",
  lightGray: "#939292",
  superLightGray: "#F1F2F5",
};

export default theme;
