import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "components/Footer";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
const MainLayout = () => {
  const [loading, setLoading] = useState(false);
  const api = async () => {
    try {
      setLoading(true);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      api();
    }, 1000);
  }, [loading]);

  return loading === false ? (
    <Loading />
  ) : (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
const AjaxContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const ContainerWrapper = styled.div`
  padding-bottom: 0px;
  margin-top: 0 !important;
  padding-top: 0px;
  flex-grow: 1;
  background-color: #fafbfc;
  position: relative;
  z-index: 10;
`;
const MainContent = styled.div`
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: 690px) {
    max-width: 220px;
  }
  @media only screen and (min-width: 480px) and (max-width: 690px) {
    max-width: 420px;
  }
  @media only screen and (min-width: 690px) and (max-width: 999px) {
    max-width: 600px;
  }
`;
const MainContentRow = styled.div`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
`;
