import "css/Main/Figure/index.css";
import figurewrap from "utils/figurewrap";
import { useNavigate } from "react-router-dom";

const FigureWrap = () => {
  const navigate = useNavigate();
  const goPage = (link) => {
    navigate(link);
  };
  return (
    <div className="figureWrap">
      {figurewrap.map((item, index) => {
        return (
          <div className="snip1190" key={index}>
            <img src={item.src} alt="" />
            <figcaption onClick={() => goPage(item.href)}>
              <div className="square">
                <div></div>
              </div>
              <h2>
                <span>{item.title}</span>
              </h2>
              <p>more +</p>
            </figcaption>
            {/* <a href="/home/page_con/patent"></a> */}
          </div>
        );
      })}
    </div>
  );
};
export default FigureWrap;
