import "css/Main/Youtube/index.css";
const Youtube = () => {
  return (
    <div className="mainyoutube">
      <div className="myou1">
        <iframe
          width="100%"
          height="415"
          src="https://www.youtube.com/embed/zjAc_ppvp68?si=5gRARzx-5nRgUKHs"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen=""
        ></iframe>
      </div>
      
    </div>
  );
};
export default Youtube;
