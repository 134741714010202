import Map from "components/Map";
import { useEffect, useRef } from "react";
import "css/Direction/direction.css";
import marking from "assets/images/direction/map_ic.png";
import tel from "assets/images/direction/tel_ic.png";
import fax from "assets/images/direction/tel_ic2.png";
const Direction = () => {
  return (
    <div id="wrapper">
      <div id="direction">
        <h2 id="direction_title" className="top sub_tit">
          <span>오시는 길 </span>
        </h2>
        <div className="map_wrapper">
          <Map />
          <p className="clfix map_txt">
            <p className="fl">
              <img src={marking} height={32} width={24} />
              <p>서울 강남구 영동대로 302 (국민제1빌딩 3,4층)</p>
            </p>

            <p className="fr">
              <p className="clfix">
                <img src={tel} className="fl" />
                <span className="fl">
                  <b>TEL</b>
                  02-3459-9600
                </span>

                <img src={fax} className="fl" />
                <span className="fl">
                  <b>FAX</b>
                  02-6234-1611
                </span>
              </p>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Direction;
