import product0301 from "assets/images/products/03/03_01.jpg";
import product0302 from "assets/images/products/03/03_02.jpg";
import product0303 from "assets/images/products/03/03_03.jpg";
import product0304 from "assets/images/products/03/03_04.jpg";
import product0305 from "assets/images/products/03/03_05.jpg";
import product0501 from "assets/images/products/05/05_01.jpg";
import product0502 from "assets/images/products/05/05_02.jpg";
import product0503 from "assets/images/products/05/05_03.jpg";
import product0504 from "assets/images/products/05/05_04.jpg";
import product0505 from "assets/images/products/05/05_05.jpg";
import product0601 from "assets/images/products/06/06_01.jpg";
import product0602 from "assets/images/products/06/06_02.jpg";
import product0603 from "assets/images/products/06/06_03.jpg";
import product0604 from "assets/images/products/06/06_04.jpg";
import product0605 from "assets/images/products/06/06_05.jpg";
import product0801 from "assets/images/products/08/08_01.jpg";
import product0802 from "assets/images/products/08/08_02.jpg";
import product0803 from "assets/images/products/08/08_03.jpg";
import product0804 from "assets/images/products/08/08_04.jpg";
import product0805 from "assets/images/products/08/08_05.jpg";
import product1001 from "assets/images/products/10/10_01.jpg";
import product1002 from "assets/images/products/10/10_02.jpg";
import product1003 from "assets/images/products/10/10_03.jpg";
import product1004 from "assets/images/products/10/10_04.jpg";
import product1005 from "assets/images/products/10/10_05.jpg";
import product38001 from "assets/images/products/380/380_01.jpg";
import product38002 from "assets/images/products/380/380_02.jpg";
import product38003 from "assets/images/products/380/380_03.jpg";
import product38004 from "assets/images/products/380/380_04.jpg";
import product38005 from "assets/images/products/380/380_05.jpg";
export const product3 = [
  {
    id: 1,
    value: "03",
    src: product0301,
  },
  {
    id: 2,
    value: "03",
    src: product0302,
  },
  {
    id: 3,
    value: "03",
    src: product0303,
  },
  {
    id: 4,
    value: "03",
    src: product0304,
  },
  {
    id: 5,
    value: "03",
    src: product0305,
  },
];
export const product5 = [
  {
    id: 1,
    value: "05",
    src: product0501,
  },
  {
    id: 2,
    value: "05",
    src: product0502,
  },
  {
    id: 3,
    value: "05",
    src: product0503,
  },
  {
    id: 4,
    value: "05",
    src: product0504,
  },
  {
    id: 5,
    value: "05",
    src: product0505,
  },
];
export const product6 = [
  {
    id: 1,
    value: "06",
    src: product0601,
  },
  {
    id: 2,
    value: "06",
    src: product0602,
  },
  {
    id: 3,
    value: "06",
    src: product0603,
  },
  {
    id: 4,
    value: "06",
    src: product0604,
  },
  {
    id: 5,
    value: "06",
    src: product0605,
  },
];
export const product8 = [
  {
    id: 1,
    value: "08",
    src: product0801,
  },
  {
    id: 2,
    value: "08",
    src: product0802,
  },
  {
    id: 3,
    value: "08",
    src: product0803,
  },
  {
    id: 4,
    value: "08",
    src: product0804,
  },
  {
    id: 5,
    value: "08",
    src: product0805,
  },
];
export const product10 = [
  {
    id: 1,
    value: "10",
    src: product1001,
  },
  {
    id: 2,
    value: "10",
    src: product1002,
  },
  {
    id: 3,
    value: "10",
    src: product1003,
  },
  {
    id: 4,
    value: "10",
    src: product1004,
  },
  {
    id: 5,
    value: "10",
    src: product1005,
  },
];
export const product380 = [
  {
    id: 1,
    value: "380",
    src: product38001,
  },
  {
    id: 2,
    value: "380",
    src: product38002,
  },
  {
    id: 3,
    value: "380",
    src: product38003,
  },
  {
    id: 4,
    value: "380",
    src: product38004,
  },
  {
    id: 5,
    value: "380",
    src: product38005,
  },
];
export const products = [
  {
    value: "03",
    content: product3,
  },
  {
    value: "05",
    content: product5,
  },
  {
    value: "06",
    content: product6,
  },
  {
    value: "08",
    content: product8,
  },
  {
    value: "10",
    content: product10,
  },
  {
    value: "380",
    content: product380,
  },
];
