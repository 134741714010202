import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Home from "../contents";
import Products from "contents/products";
import Company from "contents/company";
import Certificate from "contents/certificate";
import Direction from "contents/direction";

const Routes = () => {
  const rootRoutes = {
    path: "/",
    element: <Navigate to="/main" />,
  };
  const noMatchRoutes = {
    path: "*",
    element: <Navigate to="/main" />,
  };
  const mainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "main",
        element: <Home />,
      },
    ],
  };
  const companyRoutes = {
    path: "/company",
    element: <MainLayout />,
    children: [
      {
        path: "info",
        element: <Company />,
      },
    ],
  };
  const productRoutes = {
    path: "/products",
    element: <MainLayout />,
    children: [
      {
        path: "list",
        element: <Products />,
      },
    ],
  };
  const certificateRoutes = {
    path: "/board",
    element: <MainLayout />,
    children: [
      {
        path: "certificate",
        element: <Certificate />,
      },
    ],
  };
  const directionRoutes = {
    path: "/direction",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Direction />,
      },
    ],
  };
  const routes = [
    rootRoutes,
    noMatchRoutes,
    mainRoutes,
    productRoutes,
    companyRoutes,
    certificateRoutes,
    directionRoutes,
  ];

  return useRoutes(routes);
};
export default Routes;
