import "../css/Header/Header.css";
import logo from "assets/images/logo/logo.png";
import { useEffect, useState } from "react";
import Quickmenu from "./Quickmenu";
import header from "utils/header";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeId, setActiveId] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  const navigate = useNavigate();
  const goPage = (link) => {
    navigate(link);
    window.location.reload();
  };
  return (
    <header id="hd">
      <h1 id="hd_h1">창운산업</h1>

      <div className="to_content">
        <a href="#container">본문 바로가기</a>
      </div>

      <div id="logo" className="">
        <a href="/main">
          <img className="color_logo" src={logo} alt="로고" />
        </a>
      </div>

      <div
        id={scrollPosition < 100 ? "hd_wrapper" : "hd_wrapper_scrolled"}
        className=""
      >
        <button type="button" id="gnb_open" className="">
          <i className="fa fa-bars"></i>
          <span className="sound_only"> 메뉴열기</span>
        </button>

        <div id="gnb" className="pc_view">
          <ul className="depth">
            {header.map((item, index) => {
              return (
                <li className="depth_li" key={index}>
                  <a
                    className={
                      "mm" +
                      (item.href === window.location.pathname ? " active" : "")
                    }
                    onClick={() => goPage(item.href) & setActiveId(item.id)}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="bgGnb" style={{ overflow: "hidden" }}></div>
      </div>
      <Quickmenu />
      {/* <aside id="quickmenu1" className="quickmenu disabled quick">
        <ul id="quickmenu1-btn-/kr">
          <li>
            <a
              href="tel:063-471-0481"
              className="btn-inquiry external-link"
              data-name="구매문의"
            >
              <img src={call} alt="" />
              <span className="square1">
                <span className="label">전화문의</span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://blog.naver.com/changwoon74"
              className="btn-dealer external-link"
              data-name="블로그"
              target="_blank"
            >
              <img src={naver} alt="" />
              <span className="square1">
                <span className="label">블로그</span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCajBcbmwbyP6iByl3RSAEvA"
              className="btn-inquiry external-link"
              data-name="유튜브"
              target="_blank"
            >
              <img src={youtube} alt="" />
              <span className="square1">
                <span className="label">유튜브</span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://band.us/band/76715799"
              className="btn-dealer external-link"
              data-name="밴드"
              target="_blank"
            >
              <img src={band} alt="" />
              <span className="square1">
                <span className="label">밴드</span>
              </span>
            </a>
          </li>
        </ul>
      </aside> */}
    </header>
  );
};
export default Header;
