import { useEffect, useState } from "react";
import "css/Certificate/certificate.css";
import certificate from "assets/images/certificate/certificate.jpg";

const Certificate = () => {
  const [activeId, setActiveId] = useState(0);
  useEffect(() => {
    const animatedTags = document.querySelectorAll(".img");
    animatedTags?.forEach((tag) => {
      tag.style.opacity = 0;
    });
    const fadeIn = function () {
      let delay = 0.5;
      animatedTags?.forEach((tag) => {
        const tagTop = tag.getBoundingClientRect().top;
        const tagBottom = tag.getBoundingClientRect().bottom;

        if (tagTop < window.innerHeight && tagBottom > 0) {
          tag.style.animation = `fadein ease 1s ${delay}s both`;
          delay = delay + 0.3;
        } else {
          tag.style.opacity = 0;
          tag.style.animation = "";
        }
      });
    };
    document.addEventListener("scroll", function () {
      fadeIn();
    });
  }, []);
  return (
    <div id="wrapper">
      <div id="certificate">
        <div>
          <h2 id="certificate_title" className="top sub_tit">
            <span>특허 · 인증서 </span>
          </h2>
        </div>
        <div id="certificate_image">
          <img src={certificate} />
        </div>

        <nav className="well" style={{ textAlign: "center" }}>
          <ul className="pagination">
            <li className="page-item active">
              <a href="#" className="page-link"></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Certificate;
