import logo from "assets/images/logo/logo.png";
import "css/Quickmenu/Quickmenu.css";
import call from "assets/images/quickmenu/call.png";
import naver from "assets/images/quickmenu/naver.png";
import youtube from "assets/images/quickmenu/youtube.png";
import band from "assets/images/quickmenu/band.png";
import close from "assets/images/quickmenu/close.png";
import { useState } from "react";
const Quickmenu = () => {
	const [active, setActive] = useState(false);
	return (
		<div
		className={"quickmenu" + (active ? ' active' : '')}
		style={{right: 0}}>
		<div className="q_logo">
			<a href="/">
				<img src={logo} />
        	</a>
    	</div>
		<ul id="quickmenu1-btn-/kr">
    	      <li>
    	        <a
    	          href="tel:063-471-0481"
    	          className="btn-inquiry external-link"
    	          data-name="구매문의"
    	        >
    	          <img src={call} alt="" />
    	          <span className="square1">
    	            <span className="label">전화문의</span>
    	          </span>
    	        </a>
    	      </li>
    	      <li>
    	        <a
    	          href="https://blog.naver.com/changwoon74"
    	          className="btn-dealer external-link"
    	          data-name="블로그"
    	          target="_blank"
    	        >
    	          <img src={naver} alt="" />
    	          <span className="square1">
    	            <span className="label">블로그</span>
    	          </span>
    	        </a>
    	      </li>
    	      <li>
    	        <a
    	          href="https://www.youtube.com/channel/UCajBcbmwbyP6iByl3RSAEvA"
    	          className="btn-inquiry external-link"
    	          data-name="유튜브"
    	          target="_blank"
    	        >
    	          <img src={youtube} alt="" />
    	          <span className="square1">
    	            <span className="label">유튜브</span>
    	          </span>
    	        </a>
    	      </li>
    	      <li>
    	        <a
    	          href="https://band.us/band/76715799"
    	          className="btn-dealer external-link"
    	          data-name="밴드"
    	          target="_blank"
    	        >
    	          <img src={band} alt="" />
    	          <span className="square1">
    	            <span className="label">밴드</span>
    	          </span>
    	        </a>
    	      </li>
    	</ul>

			<div className={"quick_close" + (active ? ' active' : '')}
			 onClick={() => setActive(!active)}
			><a href="#none"></a></div>

	</div>
    )
}
export default Quickmenu;