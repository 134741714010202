import "css/Company/company.css";
import info1 from "assets/images/company/info1.jpg";
const Company = () => {
  return (
    <div id="wrapper">
      <div id="container">
        <h2 id="container_title" className="top sub_tit">
          <span>회사 소개 </span>
        </h2>
        <span className="hline_sub">&nbsp;</span>

        <p className="cen_txt">글로벌 제조산업의 심장, 탐구산업</p>
        <div className="content_wrap">
          <div className="intro_top">
            <p className="img">
              <img src={info1} alt="image" />
            </p>
            <p className="s_box txt_20">
              현대위아는 1976년 창립 이래 공작기계,
              <br className="msubnone" />
              방위산업 분야에서 최첨단 제품을 개발ㆍ생산하며
              <br className="msubnone" />
              <span className="col_ffab27">종합기계 산업분야의 선두주자</span>로
              자리매김했습니다.
            </p>
          </div>
          <div className="overflow">
            <div className="s_conts">
              <p className="s_cont txt_15">
                세계적인 자동차 부품 제조사인 현대위아는 기계를 만드는 기계,
                공작기계 분야에서 대한민국 최고의 위치를 점하고 있으며 전세계
                완성차 업체에 최상의 품질과 성능을 갖춘 혁신적 제품을
                공급합니다.
              </p>
              <p className="s_cont txt_15">
                자동차 부품의 기초 소재부터 엔진과 모듈 등속조인트 등의 부품을
                직접 생산하는 동시에 친환경 차량용 열관리 시스템과 수소전기차의
                주요 부품인 공기압축기 등을 개발하며 미래 자동차 시장 트렌드에도
                적극 대응하고 있습니다. 아울러 초정밀 공작기계와 스마트팩토리,
                협동로봇을 통해 전세계 제조업체의 생산 및 품질역량을 한 단계
                끌어올리고 있습니다.
              </p>
              <p className="s_cont txt_15">
                글로벌 제조산업의 기반을 다진 현대위아는, 이제 전폭적인 R&amp;D
                투자와 글로벌 생산 영업망의 공격적인 확장을 통해 더 나은 미래를
                향해 도전합니다.
              </p>
            </div>
          </div>
          <p className="content_tit b">기업정보</p>
          <div className="def_table_wrap touchParent">
            <div className="table_scroll">
              <table className="view_table">
                <colgroup>
                  <col style={{ width: 20 }} />
                  <col style={{ width: 30 }} />
                  <col style={{ width: 20 }} />
                  <col style={{ width: 30 }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>회사명칭</th>
                    <td>현대위아주식회사 </td>
                    <th>매출액</th>
                    <td className="right">8조 2,076억원 (2022)</td>
                  </tr>
                  <tr>
                    <th>대표이사</th>
                    <td>정재욱</td>
                    <th>영업이익</th>
                    <td className="right">2,121억원 (2022)</td>
                  </tr>
                  <tr>
                    <th>설립일자</th>
                    <td>1976년 3월 29일</td>
                    <th>신용등급</th>
                    <td className="right">AA- (2022)</td>
                  </tr>
                  <tr>
                    <th>본사주소</th>
                    <td>경상남도 창원시 성산구 정동로 153</td>
                    <th>인원현황</th>
                    <td className="right">2,917명 (2022)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Company;
