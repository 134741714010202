import "../css/Main/index.css";
import Carousel from "./main/carousel";
import Carousel2 from "./main/carousel2";
import Category from "./main/category";
import FigureWrap from "./main/figurewrap";
import Youtube from "./main/youtube";

const Home = () => {
  return (
    <>
      {/* <Carousel /> */}
      <Carousel2 />
      <Category />
      {/* <Youtube /> */}
      <FigureWrap />
    </>
  );
};
export default Home;
