import company from "assets/images/main/slide1.png";
import produts from "assets/images/main/products.jpg";
import sertificate from "assets/images/main/certificate.jpg";
import location from "assets/images/main/location.jpg";
const figurewrap = [
  {
    id: 1,
    title: "회사 소개",
    src: company,
    href: "/company/info",
  },
  {
    id: 2,
    title: "제품 소개",
    src: produts,
    href: "/products/list",
  },
  {
    id: 3,
    title: "특허 · 인증서",
    src: sertificate,
    href: "/board/certificate",
  },
  {
    id: 4,
    title: "오시는 길",
    src: location,
    href: "/direction",
  },
];
export default figurewrap;
