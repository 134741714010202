import React, { useEffect } from "react";

const Map = () => {
  useEffect(() => {
    mapscript();
  }, []);

  const mapscript = () => {
    let container = document.getElementById("map");
    let options = {
      center: new window.kakao.maps.LatLng(37.4286388, 127.1312854),
      level: 5,
    };
    //map
    const map = new window.kakao.maps.Map(container, options);

    //마커가 표시 될 위치
    let markerPosition = new window.kakao.maps.LatLng(37.4286388, 127.1312854);

    // 마커를 생성
    let marker = new window.kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커를 지도 위에 표시
    marker.setMap(map);
  };

  return (
    <div id="map" style={{ width: "1500px", height: "600px", zIndex: 1 }}></div>
  );
};
export default Map;
