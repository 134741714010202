import "css/Footer/index.css";
import logo from "assets/images/logo/logo.png";
const Footer = () => {
  return (
    <div id="ft">
      <div className="ft_wr">
        <div id="ft_copy">
          <div className="ft_copy_left">
            <img src={logo} alt="" />
          </div>
          <div className="ft_copy_right">
            <p>
              상호명 : 탐구산업　 |　대표자 : 유시영
              <br />
              주소 : 전라북도 군산시 산단로 17-33 (오식도동 815-20) <br />
              TEL : 063-471-0481　 |　E-MAIL : changwoon74@naver.com　|　FAX :
              063-471-0487
            </p>
            <p>
              COPYRIGHT ©{" "}
              <strong style={{ margin: "0 5px" }}>tamgu.co.kr</strong> ALL RIGHT
              RESERVED
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
