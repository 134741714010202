import "css/Products/product.css";
import { useEffect, useState } from "react";
import { products } from "utils/product";
const Products = () => {
  const list = [
    { id: 1, value: "03" },
    { id: 2, value: "05" },
    { id: 3, value: "06" },
    { id: 4, value: "08" },
    { id: 5, value: "10" },
    { id: 6, value: "380" },
  ];
  const [activeValue, setActiveValue] = useState("03");
  const [filterProducts, setFitlerProducts] = useState([]);

  const handlerFilter = (props) => {
    console.log("선택시:", props);
    setActiveValue(props);
    const filtered = products
      .filter((item) => item.value.includes(props))
      .map((sub) => sub.content);
    console.log("filtered:", filtered);
    setFitlerProducts(filtered[0]);
  };
  useEffect(() => {
    console.log("activeValue:", activeValue);
    console.log(filterProducts);
    handlerFilter(activeValue);
  }, [activeValue, filterProducts]);
  return (
    <div id="wrapper">
      <div id="product">
        <div>
          <h2 id="product_title" className="top sub_tit">
            <span>제품 소개 </span>
          </h2>
        </div>
        <div id="topsubmenu">
          <div className="topsub">
            {list.map((item, index) => {
              return (
                <div
                  className="pp"
                  key={index}
                  onClick={() => handlerFilter(item.value)}
                >
                  <a className={item.value === activeValue ? " active" : ""}>
                    {item.value}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div id="products">
          <div className="products_wrapper">
            {filterProducts.map((item, index) => {
              return (
                <div className="product_container" key={index}>
                  <div className="product_inner">
                    <img src={item.src} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <nav className="well" style={{ textAlign: "center" }}>
          <ul className="pagination">
            <li className="page-item active">
              <a href="#" className="page-link">
                1
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Products;
