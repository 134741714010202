import "css/Main/Category/category.css";
import category from "utils/category";
import Youtube from "../youtube";

const Category = () => {
  return (
    <div className="home-category">
      <div className="grid">
        {category.map((item, index) => {
          return (
             <a
                href="/board/lists/board_pd_intro"
              className="excavators main-page-product-category-link"
              key={index}
              >
                <span className="img">
                 {item.html}
                </span>
              <span className="label kr_sd_500">{item.title}</span>
              </a>
          )
        })}
      </div>
      <Youtube/>
      {/* <div className="row">
        <div className="col col-auto">
          <ul className="fclear">
            <li>
              <a
                href="/board/lists/board_pd_intro"
                className="excavators main-page-product-category-link"
                data-title="03 버켓"
              >
                <span className="img">
                 
                </span>
                <span className="label kr_sd_500">03 버켓</span>
              </a>
            </li>
            <li>
              <a
                href="/board/lists/board_six"
                className="wheel-loaders main-page-product-category-link"
                data-title="06 버켓"
              >
                <span className="img">
                  
                </span>
                <span className="label kr_sd_500">06 버켓</span>
              </a>
            </li>
            <li>
              <a
                href="/board/lists/board_08"
                className="articulated-dump-trucks main-page-product-category-link"
              >
                <span className="img">
                  
                </span>
                <span className="label">08 버켓</span>
              </a>
            </li>
            <li>
              <a href="/board/lists/board_ten">
                <span className="img">
                  
                </span>
                <span className="label">10 버켓</span>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};
export default Category;
