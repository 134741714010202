import "css/Main/Carousel2/index.css";

import slide2 from "assets/images/main/slide2.jpg";
import slide3 from "assets/images/main/slide1.png";
import { useEffect } from "react";
const Carousel2 = () => {
  // useEffect(() => {
  //   let mainPosts = document.querySelectorAll(".main-post");
  //   let posts = document.querySelectorAll(".post");
  //   let i = 0;
  //   let postIndex = 0;
  //   let currentPost = posts[postIndex];
  //   let currentMainPost = mainPosts[postIndex];
  //   let progressInterval = setInterval(progress, 100); // 180

  //   function progress() {
  //     if (i === 100) {
  //       i = -5;
  //       // reset progress bar
  //       currentPost.querySelector(".progress-bar__fill").style.width = 0;
  //       document.querySelector(
  //         ".progress-bar--primary .progress-bar__fill"
  //       ).style.width = 0;
  //       currentPost.classList.remove("post--active");

  //       postIndex++;

  //       currentMainPost.classList.add("main-post--not-active");
  //       currentMainPost.classList.remove("main-post--active");

  //       // reset postIndex to loop over the slides again
  //       if (postIndex === posts.length) {
  //         postIndex = 0;
  //       }

  //       currentPost = posts[postIndex];
  //       currentMainPost = mainPosts[postIndex];
  //     } else {
  //       i++;
  //       currentPost.querySelector(".progress-bar__fill").style.width = `${i}%`;
  //       document.querySelector(
  //         ".progress-bar--primary .progress-bar__fill"
  //       ).style.width = `${i}%`;
  //       currentPost.classList.add("post--active");

  //       currentMainPost.classList.add("main-post--active");
  //       currentMainPost.classList.remove("main-post--not-active");
  //     }
  //   }
  // });
  return (
    <>
      <div className="carousel">
        <div className="progress-bar progress-bar--primary hide-on-desktop">
          <div className="progress-bar__fill"></div>
        </div>

        <header className="main-post-wrapper">
          <div className="slides">
            <article className="main-post main-post--active">
              <div className="main-post__image">
                <img
                  src={slide3}
                  alt="New McLaren wind tunnel 'critical' to future performance, says Tech Director Key"
                />
              </div>
              <div className="main-post__content">
                <div className="main-post__tag-wrapper">
                  <h2 className="main-post__tag">TAMGU</h2>
                  <h1 className="main-post__title">
                    What To Watch For in the 2019 Hungarian Grand Prix
                  </h1>
                </div>
              </div>
            </article>
            <article className="main-post main-post--not-active">
              <div className="main-post__image">
                <img
                  src={slide2}
                  alt="What To Watch For in the 2019 Hungarian Grand Prix"
                />
              </div>
              <div className="main-post__content">
                <div className="main-post__tag-wrapper">
                  <h2 className="main-post__tag">TAMGU</h2>
                  <h1 className="main-post__title">
                    What To Watch For in the 2019 Hungarian Grand Prix
                  </h1>
                </div>
              </div>
            </article>
            <article className="main-post main-post--not-active">
              <div className="main-post__image">
                <img
                  src={slide3}
                  alt="Hamilton wants harder championship fight from Leclerc and
            Verstappen"
                />
              </div>
              <div className="main-post__content">
                <div className="main-post__tag-wrapper">
                  <h2 className="main-post__tag">TAMGU</h2>
                  <h1 className="main-post__title">
                    What To Watch For in the 2019 Hungarian Grand Prix
                  </h1>
                </div>
              </div>
            </article>
          </div>
        </header>

        <div className="posts-wrapper hide-on-mobile">
          <article className="post post--active">
            <div className="progress-bar">
              <div className="progress-bar__fill"></div>
            </div>
          </article>
          <article className="post">
            <div className="progress-bar">
              <div className="progress-bar__fill"></div>
            </div>
          </article>
          <article className="post">
            <div className="progress-bar">
              <div className="progress-bar__fill"></div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};
export default Carousel2;
